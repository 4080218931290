.images-container {
    margin-top : 40px;

    &.news {
        margin-top : 20px;
    }

    input {
        display : none;

        &.show-input {
            display : block;
        }
    }

    .upload {
        position      : relative;
        display       : inline-block;
        box-shadow    : 0 0 2px 1px #efeeee;
        border        : 1px solid rgba(0, 0, 0, 0.1);
        border-radius : 4px;
        width         : 45%;
        max-width     : 900px;
        height        : 268px;
        background    : #FFFFFF;

        > div {
            position   : absolute;
            top        : 50%;
            left       : 50%;
            display    : block;
            transform  : translate(-50%, -50%);
            text-align : center;

            div {
                font-size      : 16px;
                line-height    : 100%;
                vertical-align : middle;

                &:nth-child(1) {
                    font-weight : 600;
                }

                &:nth-child(2) {
                    margin     : 12px 0;
                    font-style : italic;
                    color      : #b9b9b9;
                }
            }

            label {
                margin-top : 12px;
            }

            input {
                display : none;
            }
        }
    }

    .images {
        margin-top : 26px;

        &.news {
            .image {
                width  : 199px;
                height : 199px;

                &:nth-of-type(5n) {
                    margin-right : 0;
                }

                .preview {
                    width  : 199px;
                    height : 199px;
                }
            }
        }

        .image {
            position      : relative;
            display       : inline-block;
            margin        : 14px 14px 14px 0;
            border-radius : 4px;
            width         : 250px;
            cursor        : pointer;

            &:hover {
                div.delete-image, div.drag-handle {
                    display    : block;
                    visibility : visible;
                }
            }

            div.delete-image, div.drag-handle {
                position      : absolute;
                top           : 8px;
                right         : 8px;
                display       : none;
                opacity       : 0.5;
                border-radius : 50%;
                width         : 32px;
                height        : 32px;
                background    : rgba(11, 37, 69, 0.85);
                visibility    : hidden;

                img {
                    width   : 32px;
                    height  : 32px;
                    padding : 5px;

                    &:hover {
                        cursor : pointer;
                    }
                }
            }

            div.drag-handle {
                right  : 0;
                left   : 8px;
                cursor : pointer;
            }

            img {
                border-radius : 4px;
                width         : 250px;
                height        : 250px;
                object-fit    : cover;
            }
        }
    }
}
