.pagination {
    display : flex;

    &--push-top {
        margin-top : 32px;
    }

    span {
        cursor          : pointer;
        display         : flex;
        width           : 40px;
        height          : 40px;
        border-radius   : 6px;
        margin-right    : 8px;
        border          : 1px solid #d7d7d7;

        align-items     : center;
        justify-content : center;

        color           : #000;
        font-size       : 16px;
        font-weight     : 500;

        transition      : all ease-in-out 0.03s;

        &.active {
            color      : #fff;
            background : #028090;
            border     : 1px solid transparent;
        }
    }
}
