.question-row {
    background : #ffffff;

    .question-row {
        background : transparent;
    }

    &:first-child {
        .question-row-columns {
            box-shadow : 0 -1px 0 0 #E5E5E5;
        }
    }

    &--has-children {
        background : transparent;
    }

    &-columns {
        width          : 100%;
        display        : flex;
        flex-direction : row;
        height         : 64px;
        line-height    : 64px;
        padding-left   : 16px;
        padding-right  : 16px;
        box-shadow     : 0 1px 0 0 #E5E5E5;

        .column {
            text-overflow : ellipsis;
            white-space   : nowrap;
            overflow      : hidden;
            text-align    : left;
            width         : calc(50% - 10px);

            &-1 {
                width        : calc(50% - 30px);
                padding-left : 16px;
            }

            &-2 {
                width        : calc(50% - 0px);
                padding-left : 16px;
            }
        }

        .column-right {
            width : 40px;
            float : right;
        }
    }

    .pointer {
        cursor : pointer;

        &:hover {
            background : #F3F3F3;
        }
    }
}
