.modal-container {
    .modal-background {
        position   : fixed;
        top        : 0;
        left       : 0;
        height     : 100vh;
        width      : 100vw;
        background : #0D0D0D;
        opacity    : 0.5;
        z-index    : 999;
    }

    .modal-wrapper {
        display         : flex;
        justify-content : center;
        align-items     : flex-start;
        position        : fixed;
        top             : 0;
        left            : 0;
        height          : 100vh;
        width           : 100vw;
        z-index         : 1000;

        .modal {
            display       : block;
            position      : relative;
            margin-top    : 144px;
            width         : 600px;
            padding       : 24px;
            background    : #FAFCFE;
            box-shadow    : 0 16px 32px rgba(0, 0, 0, 0.35);
            border-radius : 12px;
            height        : initial;

            h1 {
                margin-top    : 0;
                margin-bottom : 24px;
                font-weight   : bold;
                font-size     : 24px;
                line-height   : 40px;
            }

            p {
                font-size   : 16px;
                line-height : 20px;
            }

            label {
                font-family : Roboto, sans-serif;
                font-weight : 500;
                font-size   : 12px;
                line-height : 14px;
                color       : #1A1A1A;
                opacity     : 0.7;
            }

            input[type=text] {
                margin-top    : 6px;
                padding-left  : 16px;
                display       : block;
                width         : 100%;
                border        : 1px solid rgba(0, 0, 0, 0.1);
                border-radius : 4px;
                font-family   : Roboto, sans-serif;
                font-style    : normal;
                font-weight   : 500;
                font-size     : 14px;
                line-height   : 48px;
                color         : #1A1A1A;
                margin-bottom : 16px;
            }
        }
    }
}
