.table {
    width           : 100%;
    border-collapse : inherit;

    tr {
        box-shadow : 0 1px 0 0 #E5E5E5;
        height     : 64px;
        cursor     : pointer;

        th, td {
            &.status {
                width : 120px;
            }

            &.small {
                width : 40px;
            }

            &.highlighted {
                width   : 120px;
                padding : 10px 16px;

                .checkbox {
                    border-radius : 2px;
                    width         : 20px;
                    height        : 20px;
                    background    : #028090;

                    &.checked {
                        background-image    : url('/svgs/checkmark.svg');
                        background-repeat   : no-repeat;
                        background-size     : 13px;
                        background-position : 3px;
                    }
                }
            }
        }

        th {
            opacity        : 0.4;
            border-top     : none;
            padding-top    : 0;
            padding-bottom : 0;
            padding-left   : 16px;
            cursor         : default;
            font-size      : 16px;
            font-weight    : 500;
            line-height    : 64px;
            text-align     : left;
            white-space    : nowrap;
        }

        td {
            padding-top    : 0;
            padding-bottom : 0;
            padding-left   : 16px;
            line-height    : 64px;

            &.status {
                .indicator {
                    transform     : translateY(5px);
                    border-radius : 50%;
                    width         : 20px;
                    height        : 20px;

                    &.red {
                        background : red;
                    }

                    &.green {
                        background : green;
                    }
                }
            }

            .product-label {
                padding     : 0 10px;
                border      : 1px solid black;
                width       : 200px;
                display     : inline-block;
                text-align  : center;
                line-height : 36px;
                box-sizing  : border-box;
            }

            &.show-links {
                a {
                    text-decoration : underline;
                }
            }
        }
    }
}

.custom-table {
    .thead {
        .item {
            font-size : 16px;
            color     : #9f9fa0;
        }
    }

    .category-item {
        cursor : pointer;
    }

    .item {
        border-bottom : 1px solid #E5E5E5;
        height        : 64px;
        padding-left  : 16px;
        line-height   : 64px;
        color         : black;

        &.sub-item {
            padding-left : 40px;
        }
    }

    .no-results {
        height       : 64px;
        padding-left : 16px;
        line-height  : 64px;
    }
}
