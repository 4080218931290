.content-wrap {
    margin-left : 280px;
    padding     : 80px;
}

.page-header {
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    margin-bottom   : 58px;

    &--without-push {
        margin-bottom : 0;
    }

    &--push-top {
        margin-top : 32px;
    }
}

.page-title {
    font-size   : 32px;
    font-weight : 700;
    line-height : 32px;

    &--sub {
        margin-top  : 8px;
        font-size   : 18px;
        line-height : 18px;
        color       : rgba(0, 0, 0, 0.4);

        a {
            color : rgba(0, 0, 0, 0.4);

            &:hover {
                text-decoration : underline;
            }
        }
    }
}
