.options-menu {
    position : relative;
    cursor   : pointer;
    display  : flex;

    .options-menu-items {
        position      : absolute;
        top           : 100%;
        right         : 0;
        padding       : 16px 0;
        margin-top    : 8px;
        min-width     : 160px;

        background    : linear-gradient(180deg, #009EB3 0%, #027988 100%);
        box-shadow    : 0 12px 26px rgba(0, 0, 0, 0.25);
        border-radius : 8px;

        opacity       : 0;
        visibility    : hidden;
        transition    : all ease-in-out 0.15s;
        z-index       : 99;

        a {
            display     : block;
            padding     : 6px 16px;

            color       : #fff;
            font-size   : 14px;
            line-height : 20px;

            &:hover {
                background : rgba(255, 255, 255, 0.2);
            }
        }
    }

    &.active {
        .options-menu-items {
            opacity    : 1;
            visibility : visible;
        }
    }
}
