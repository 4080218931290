.tabs {
    display     : flex;
    font-weight : 600;
    font-size   : 18px;
    line-height : 18px;
    box-shadow  : 0 1px 0 rgba(0, 0, 0, 0.1);

    &--push-less {
        margin-bottom : 32px;
    }

    &--push-more {
        margin-bottom : 64px;
    }

    a {
        padding : 21px 32px;

        &.router-link-exact-active,
        &.active {
            box-shadow : inset 0px -3px 0px #F26419;
        }

        &:hover {
            text-decoration: none;
            color: black;
        }
    }
}
