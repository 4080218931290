.multiselect__tags {
    border      : 0;
    outline     : none;
    width       : 100%;
    max-width   : 100%;
    min-height  : 48px;
    padding     : 0 16px;
    background  : transparent;
    font-size   : 14px;
    font-weight : 500;
    line-height : 48px;
    color       : #1A1A1A;
    position    : relative;

    input {
        padding : 0;
    }
}

.multiselect {
    position : relative;

    .multiselect__clear {
        position        : absolute;
        right           : 14px;
        top             : 14px;
        z-index         : 9;
        cursor          : pointer;

        background      : #DB4649 url('/svgs/close-icon.svg') center;
        background-size : 80%;

        width           : 18px;
        height          : 18px;
        border-radius   : 50%;

        &:hover {
            background-color: darken(#DB4649, 15);
        }
    }
}

.multiselect__tag {
    display       : inline-flex;
    margin-left   : 4px;

    border-radius : 2px;
    padding       : 4px 8px;
    background    : linear-gradient(180deg, #009EB3 0%, #027988 100%);
    line-height   : 24px;
    color         : #FFFFFF;

    &:first-child {
        margin-left : 0;
    }

    &-icon {
        align-self          : center;
        margin-left         : 8px;

        width               : 14px;
        height              : 14px;
        background-image    : url('/svgs/close-icon.svg');

        background-repeat   : no-repeat;
        background-position : center;
        cursor              : pointer;
    }
}

.multiselect__content {
    overflow-y            : auto;
    box-shadow            : 0px 12px 26px rgba(0, 0, 0, 0.5);
    -moz-border-radius    : 4px;
    -webkit-border-radius : 4px;
    border-radius         : 4px;
    width                 : 100%;
    max-height            : 250px;
    background            : linear-gradient(180deg, #009EB3 0%, #027988 100%);
    color                 : #FFFFFF;

    .multiselect__element {
        .multiselect__option {
            position        : relative;
            display         : block;
            cursor          : pointer;
            line-height     : 16px;
            vertical-align  : middle;
            white-space     : nowrap;
            text-decoration : none;
            text-transform  : none;

            &--selected {
                padding-right       : 16px;
                background          : rgba(255, 255, 255, 0.2);
                background-image    : url('/svgs/checkmark.svg');
                background-repeat   : no-repeat;
                background-position : right 16px center;
            }

            span {
                min-width    : 100%;
                min-height   : 42px;
                padding-left : 16px;
                line-height  : 42px;
            }
        }

        &:hover {
            background : rgba(255, 255, 255, 0.2);
        }
    }
}

.filter-white {
    filter : grayscale(1) brightness(10);
}

.filter-black {
    filter : grayscale(1) brightness(0);
}

.multiselect.category-icon {
    .multiselect__tags {
        .multiselect__single {
            display : inline;

            span {
                display     : flex;
                align-items : center;
                height      : 56px;

                img {
                    display : block;
                }
            }
        }
    }

    .multiselect__content {
        display   : flex !important;
        flex-flow : row wrap;

        .multiselect__element {
            display : inline-block;

            &:hover {
                background-color : rgba(255, 255, 255, .16);
            }

            .multiselect__option {
                display       : inline;
                padding-right : 0;
                background    : none;

                span {
                    display         : flex;
                    align-items     : center;
                    justify-content : center;
                    min-width       : 72px;
                    height          : 56px;
                    max-height      : 56px;
                    padding         : 0;

                    img {
                        display : block;
                    }
                }
            }
        }
    }
}

.multiselect__spinner {
    position          : absolute;
    right             : 20px;
    top               : 50%;
    margin-top        : -10px;
    display           : inline-block;
    width             : 20px;
    height            : 20px;
    border            : 2px solid #F26419;
    border-radius     : 50%;
    border-top-color  : #FFFFFF;
    animation         : spin 1s ease-in-out infinite;
    -webkit-animation : spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform : rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform : rotate(360deg);
    }
}