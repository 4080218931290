.half-size {
    width : 50%;
}

.space-between {
    justify-content : space-between;
}

.text-right {
    text-align : right;
}

.invalid {
    border : 1px solid #c80d0d !important;
}

.grey-text {
    color       : #b2b2b2;
    font-size   : 16px;
    line-height : 20px;
    font-weight : 500;

    &--center {
        align-self : center;
    }

    &--push {
        padding : 0 4px;
    }
}
