.btn {
    display       : inline-block;
    border-radius : 4px;
    height        : 48px;
    padding       : 0 32px;
    cursor        : pointer;
    font-size     : 16px;
    font-weight   : 600;
    line-height   : 48px;
    color         : #FFFFFF;
    transition    : background-color .3s ease-out, transform .3s ease-out;

    &:hover:not(.disabled) {
        transform : translateY(-1px);
    }

    &:not(&:first-child) {
        margin-left : 16px;
    }

    &--push-right {
        margin-right : 16px;
    }

    &--link {
        background-color : transparent;
        color            : #9A9EA0;
    }

    &--primary {
        box-shadow       : 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        background-color : #028090;

        &:hover:not(.disabled) {
            background-color : darken(#028090, 5%);
            color            : #FFFFFF;
        }
    }

    &--secondary {
        background-color : rgba(2, 128, 144, 0.2);
        color            : rgba(6, 14, 19, 0.7);

        &:hover:not(.disabled) {
            background-color : darken(rgba(2, 128, 144, 0.2), 5%);
        }
    }

    &--orange {
        box-shadow       : 0 2px 4px rgba(0, 0, 0, 0.25);
        background-color : #F26419;

        &:hover:not(.disabled) {
            background-color : darken(#F26419, 5%);
        }
    }

    &--green {
        box-shadow       : 0 2px 4px rgba(0, 0, 0, 0.25);
        background-color : #30AF4C;

        &:hover:not(.disabled) {
            background-color : darken(#30AF4C, 5%);
        }
    }

    &--red {
        box-shadow       : 0 2px 4px rgba(0, 0, 0, 0.25);
        background-color : #DB4649;

        &:hover:not(.disabled) {
            background-color : darken(#DB4649, 5%);
        }
    }

    &.btn--light-blue {
        box-shadow : 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        background : #C8E2E6;
        color      : black;

        &:hover:not(.disabled) {
            background-color : darken(#C8E2E6, 5%);
        }
    }

    &.btn--blue {
        box-shadow : 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        background : #0069B4;

        &:hover:not(.disabled) {
            background-color : darken(#0069B4, 5%);
            color            : #FFFFFF;
        }
    }

    &-wrap {
        display         : flex;
        justify-content : center;

        &--push-top {
            margin-top : 32px;
        }

        &--right {
            justify-content : flex-end;
        }
    }

    &.disabled {
        opacity : 0.75;
        cursor  : not-allowed;
    }
}

.circle-btn {
    display         : inline-flex;
    align-items     : center;
    justify-content : center;
    border-radius   : 100%;
    width           : 40px;
    height          : 40px;
    background      : rgba(2, 128, 144, 0.1);

    cursor          : pointer;

    transition      : all ease-in-out 0.1s;

    svg {
        opacity : 0.4;
    }

    &--no-background {
        background : transparent;

        &:hover {
            background : rgba(2, 128, 144, 0.1);

            svg {
                opacity : 1;
            }
        }
    }
}

.btn-toggle-wrap {
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    border-radius   : 6px;
    height          : 48px;
    padding         : 4px;

    background      : #DBEDF0;

    &--push-bottom {
        margin-bottom : 32px;
    }

    &--disabled {
        pointer-events : none;
    }

    .btn-toggle {
        border-radius    : 6px;
        width            : calc(50% - 3px);
        height           : 100%;

        background-color : transparent;
        font-size        : 16px;
        text-align       : center;

        transition       : color, background-color 0.5s ease;

        &:hover {
            background : #0000000D;
            cursor     : pointer;
        }

        &--active {
            background : linear-gradient(180deg, #009EB3 0%, #027988 100%);
            color      : #FFFFFF;

            &:hover {
                background : linear-gradient(180deg, darken(#009EB3, 5%) 0%, darken(#027988, 5%) 100%);
            }
        }
    }
}
