.panel {
    background    : #fff;
    padding       : 40px 16px;
    border-radius : 4px;
    border        : 1px solid rgba(0, 0, 0, 0.02);
    box-shadow    : 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom : 16px;

    &-heading {
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        margin-bottom   : 16px;
    }

    &-title {
        color       : #000000;
        font-size   : 18px;
        line-height : 24px;
        font-weight : 500;

        &--brighter {
            font-size : 16px;
            opacity   : 0.7;
        }

        &--push {
            margin-bottom : 16px;
        }
    }

    .sub-panel {
        background    : #F9FAFB;
        border        : 1px solid rgba(0, 0, 0, 0.1);
        border-radius : 4px;
        padding       : 32px 16px;
        margin-top    : 16px;

        &:first-child {
            margin-top : 0;
        }

        &-heading {
            display         : flex;
            justify-content : space-between;
            align-items     : center;
            margin-bottom   : 16px;

            .ordering {
                margin-left  : auto;
                margin-right : 10px;
                display      : flex;

                .order {
                    cursor       : pointer;
                    margin-right : 10px;
                    width        : 15px;
                    height       : 15px;
                }
            }
        }

        &-title {
            color       : #000000;
            font-size   : 14px;
            line-height : 16px;
            font-weight : 500;
            opacity     : 0.7;
        }
    }
}

.new-row {
    display     : inline-flex;
    align-items : center;
    margin-top  : 40px;
    cursor      : pointer;

    &__circle {
        display         : inline-flex;
        justify-content : center;
        align-items     : center;
        width           : 32px;
        height          : 32px;
        border-radius   : 100%;
        margin-right    : 8px;

        background      : linear-gradient(180deg, #009EB3 0%, #027988 100%);
        box-shadow      : 0 3px 5px rgba(0, 0, 0, 0.2);
    }

    span {
        color       : #060E13;
        font-size   : 14px;
        line-height : 16px;
        opacity     : 0.7;
        font-weight : 500;
    }
}

.flip-list-move {
    transition: transform 0.2s;
}
