.field {
    $field        : '.field';
    margin-bottom : 16px;

    &--push-medium {
        margin-bottom : 24px;
    }

    &--push-hard {
        margin-bottom : 40px;
    }

    &--push-harder {
        margin-bottom : 64px;
    }

    &--no-margin {
        margin-bottom : 0;
    }

    &.sub-field {
        margin-left : 40px;
    }

    &-wrapper {
        display         : flex;
        justify-content : space-between;

        &--2 {
            .field {
                width : 45%;
            }
        }
    }

    .label {
        display       : block;
        margin-bottom : 6px;
        font-size     : 12px;
        font-weight   : 500;
        line-height   : 14px;

        color         : rgba(26, 26, 26, 0.7);

        .title {
            color         : rgba(26, 26, 26, 1);
            display       : block;
            font-size     : 14px;
            margin-bottom : 5px;
        }
    }

    &-control {
        border        : 1px solid rgba(0, 0, 0, 0.1);
        border-radius : 4px;
        padding       : 0;
        background    : #FFFFFF;

        input,
        select,
        textarea {
            border      : 0;
            outline     : none;
            width       : 100%;
            max-width   : 100%;
            height      : 48px;
            padding     : 0 16px;
            background  : transparent;

            font-size   : 14px;
            font-weight : 500;
            line-height : 48px;
            color       : #1A1A1A;

            &:disabled {
                background : #F9FAFB;
            }
        }

        textarea {
            max-width      : 100%;
            height         : 144px;
            padding-top    : 16px;
            padding-bottom : 16px;
            line-height    : 18px;
        }

        &--tags {
            position     : relative;
            display      : flex;
            align-items  : center;
            flex-wrap    : wrap;
            min-height   : 48px;
            padding-top  : 4px;
            padding-left : 16px;

            &--empty {
                padding-left : 0;
            }

            .tag-list {
                position      : absolute;
                z-index       : 999;
                top           : 100%;
                left          : 0;

                overflow-y    : auto;
                margin-top    : 4px;
                box-shadow    : 0 12px 26px rgba(0, 0, 0, 0.25);
                border-radius : 4px;

                width         : 100%;
                max-height    : 250px;

                background    : linear-gradient(180deg, #009EB3 0%, #027988 100%);

                &-item {
                    display         : flex;
                    justify-content : space-between;
                    padding         : 6px 16px;

                    cursor          : pointer;
                    font-size       : 14px;
                    font-weight     : 500;
                    line-height     : 40px;
                    color           : #FFFFFF;

                    &:hover,
                    &.active {
                        background : rgba(255, 255, 255, 0.2);
                    }
                }
            }

            .tag {
                margin-bottom : 4px;
                margin-left   : 4px;
                border-radius : 2px;
                padding       : 4px 8px;
                background    : linear-gradient(180deg, #009EB3 0%, #027988 100%);

                &-title {
                    display      : inline-flex;
                    margin-right : 4px;
                    color        : #FFFFFF;

                    &--push-bottom {
                        margin-bottom : 4px;
                    }
                }

                &-remove {
                    display     : flex;
                    align-self  : center;
                    margin-left : 8px;
                    cursor      : pointer;
                    font-size   : 12px;
                    font-weight : bold;
                    color       : #FFFFFF;

                    img {
                        width  : 14px;
                        height : 14px;
                    }
                }

                &-details {
                    font-size   : 10px;
                    font-weight : bold;
                    color       : #FFFFFF;
                }
            }

            input {
                flex          : 1;
                height        : 24px;
                padding-right : 0;
                padding-left  : 8px;
            }
        }
    }

    &--checkbox {
        input[type='checkbox'] {
            display : none;
        }

        #{$field} {
            &-control {
                position      : relative;
                display       : inline-flex;
                align-items   : center;
                border        : 0;
                border-radius : 0;

                padding       : 0 0 0 40px;
                background    : transparent;
                cursor        : pointer;

                label {
                    cursor      : pointer;
                    font-size   : 14px;
                    font-weight : 500;
                    line-height : 24px;
                    color       : #1A1A1A;

                    &:before,
                    &:after {
                        content  : '';
                        position : absolute;
                        top      : 0;
                        left     : 0;
                        width    : 24px;
                        height   : 24px;
                    }

                    &:before {
                        border-radius : 3px;
                        background    : linear-gradient(180deg, #009EB3 0%, #027988 100%);
                    }

                    &:after {
                        background-repeat   : no-repeat;
                        background-position : center;
                    }
                }
            }
        }

        input[type='checkbox']:checked + #{$field}-control label {
            &:after {
                background-image : url('/svgs/checkmark.svg');
            }
        }

        input[type='checkbox']:checked + label {
            &:after {
                background-image : url('/svgs/checkmark.svg');
            }
        }
    }

    &--image {
        input[type='file'] {
            display : none;
        }

        .image {
            margin        : auto;
            box-shadow    : 0px 0px 7px 1px grey;
            border-radius : 4px;
            max-width     : 100%;
            height        : 95%;
            object-fit    : contain;
        }

        .field-control {
            display       : flex;
            border        : 1px solid #E5E5E5;
            border-radius : 4px;
            height        : 250px;

            .image-container {
                position : relative;
                display  : flex;
                margin   : 0 auto;

                .icon {
                    position      : absolute;
                    top           : 10px;
                    right         : 5px;
                    box-shadow    : none;
                    border-radius : 50%;
                    width         : 30px;
                    height        : 30px;
                    background    : grey;
                    transition    : all 0.3s ease;

                    img {
                        width  : 100%;
                        height : 100%;
                    }

                    &:hover {
                        background : darken(grey, 5);
                        cursor     : pointer;
                    }
                }
            }

            .btn {
                margin : auto;
            }
        }
    }

    &--upload {
        #{$field} {
            &-control {
                position      : relative;
                border        : 0;
                border-radius : 0;
            }
        }

        input[type='file'] {
            display : none;
        }

        img {
            width     : 128px;
            max-width : 100%;
        }
    }

    &--row {
        display        : flex;
        flex-direction : row;

        :not(:last-child) {
            margin-right : 16px;
        }
    }
}

.field-wrapper {
    flex-wrap : wrap;
}

.field-wrapper, .field {
    .error-message {
        border        : 2px solid #DB4649;
        border-radius : 4px;
        padding       : 10px;
        font-size     : 14px;
        background    : #F5DEDF;
        margin        : 10px 0px;
    }

    .field-control {
        &.error {
            input, textarea, .redactor-box {
                outline : 1px solid red;
            }
        }
    }
}
