.question-configurator {
    display         : flex;
    justify-content : space-between;

    & > div {
        width        : 50%;
        padding-left : 80px;

        &:first-child {
            padding-left  : 0;
            padding-right : 80px;
            border-right  : 1px solid rgba(6, 14, 19, 0.1);
        }
    }

    h4 {
        color         : #000000;
        font-size     : 18px;
        line-height   : 100%;
        font-weight   : 500;
        margin-bottom : 24px;
    }
}
