.sortable-chosen {
    opacity          : 0.7;
    background-color : #dcdcdc;
}

.sortable-ghost {
    background-color : #dcdcdc;
}

.normal {
    background-color : grey;
}
