.menu {
    position           : fixed;
    z-index            : 2;
    top                : 0;
    box-shadow         : 2px -1px 4px 0 rgba(0, 0, 0, 0.15);
    width              : 280px;
    height             : 100vh;
    background-color   : #002D4D;
    display            : grid;
    grid-template-rows : 170px 1fr 75px;

    .logo {
        margin : 64px 0 40px 16px;
        width  : 260px;

        &--mini {
            display : none;
        }
    }

    .menu-items {
        overflow : auto;

        .menu-item {
            margin-bottom : 16px;
            margin-left   : 16px;

            a {
                display         : inline-block;
                border-radius   : 4px;
                box-sizing      : border-box;
                width           : 248px;
                height          : 48px;
                padding-right   : 8px;
                padding-left    : 16px;
                font-size       : 18px;
                font-weight     : 600;
                line-height     : 48px;
                vertical-align  : middle;
                text-decoration : none;
                letter-spacing  : 0.2px;
                color           : white;
                transition      : background .2s ease-out;

                svg {
                    vertical-align : middle;
                    fill           : #FFFFFF;
                }

                &.router-link-active {
                    background-color : #ffffff;
                    color            : #F26419;

                    svg {
                        fill : #F26419;
                    }
                }
            }
        }
    }

    .profile {
        margin-bottom    : 16px;
        margin-left      : 16px;
        border-radius    : 6px;
        width            : calc(280px - 32px);
        background-color : #19425E;
        cursor           : pointer;
        transition       : all 0.2s ease-out;

        .profile-wrapper {
            display     : flex;
            align-items : center;
            padding     : 18px 15px;

            span {
                flex-grow      : 1;
                font-size      : 16px;
                font-weight    : 600;
                line-height    : 22px;
                letter-spacing : 0.18px;
                color          : rgba(255, 255, 255, 0.6);
                transition     : all 0.2s ease-out;
            }

            .arrows {
                display        : flex;
                flex-direction : column;

                .arrow-up,
                .arrow-down {
                    opacity : 0.4;
                    width   : 0;
                    height  : 0;
                }

                .arrow-up {
                    margin-bottom : 3px;
                    border-right  : 5px solid transparent;
                    border-bottom : 5px solid #ffffff;
                    border-left   : 5px solid transparent;
                }

                .arrow-down {
                    border-top   : 5px solid #ffffff;
                    border-right : 5px solid transparent;
                    border-left  : 5px solid transparent;
                }
            }
        }

        .drop-down-menu {
            position         : absolute;
            bottom           : 75px;
            opacity          : 0;
            border-radius    : 4px;
            width            : calc(100% - 32px);
            padding          : 8px;
            background-color : #19425E;
            transition       : all 0.1s ease-out;
            visibility       : hidden;

            a {
                display         : flex;
                justify-content : space-between;
                margin          : 0 0 8px 0;
                border-radius   : 4px;
                padding         : 14px 16px;
                font-size       : 16px;
                font-weight     : 600;
                line-height     : 18px;
                text-decoration : none;
                letter-spacing  : 0.18px;
                color           : rgba(255, 255, 255, 0.7);
                transition      : all 0.2s ease-out;

                &:hover {
                    background-color : rgba(255, 255, 255, .15);
                    color            : white;
                }
            }
        }

        &:hover {
            .drop-down-menu {
                opacity    : 1;
                transform  : translateY(0);
                transition : all 0.2s ease-out 0.1s;
                visibility : visible;
            }
        }
    }
}
