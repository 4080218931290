.filters {
    display        : flex;
    height         : 100%;
    padding-bottom : 32px;

    .search {
        display      : block;
        position     : relative;
        margin-right : 16px;

        img {
            margin-left : 16px;
            margin-top  : 14px;
            position    : absolute;
            z-index     : 3;
        }

        input {
            background-color : #DFE5EB;
            border-radius    : 4px;
            border           : none;
            box-shadow       : inset 0 1px 3px 0 rgba(0, 0, 0, 0.05);
            box-sizing       : border-box;
            font-size        : 16px;
            height           : 48px;
            padding-left     : 56px;
            width            : 456px;
            margin-right     : 0;

            &::placeholder {
                opacity     : 0.3;
                font-weight : 600;
                line-height : 48px;
            }
        }
    }
}
